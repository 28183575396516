.container {
  width: 100%;

  margin: auto;

  display: flex;
  justify-content: center;
  align-items: center;
}

.container .contValue {
  margin: auto 10px;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.container .contValue h2 {
  color: var(--color-dark-blue);
  font-size: 25px;
  line-height: 25px;

  margin: auto;
}

.container .contValue .circleContainer {
  position: relative;

  margin: auto;

  width: 100px;
  height: 100px;
}

.container .contValue .circleContainer h3 {
  position: absolute;
  top: 0%;
  left: 0%;

  width: 100%;
  height: 100%;

  font-size: 45px;
  line-height: 45px;
  color: var(--color-dark-blue);
  text-shadow: 1px 1px 0px var(--color-light-blue);
  text-align: center;

  margin: auto;

  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: center;
}

.container .contValue .circleContainer .bgCircle {
  position: absolute;
  top: 0;
  left: 0;

  width: 95px;
  height: 95px;

  background-color: rgba(255, 255, 255, .6);

  border: 2px solid #FFF;
  border-radius: 200px;

  z-index: -1;
}

.container .contValue .circleContainer div.svg {
  z-index: 100000;

  width: 100px;
  height: 100px;

  transform: rotate(-90deg) rotateY(0deg);
}

.container .contValue .circleContainer .circleForm {
  transition: stroke-dashoffset 0.35s;
}


/* ==================================================== */


@media screen and (max-width: 768px) {
  .container .contValue {
    margin: auto 1vw;
  }
  .container .contValue h2 {
    font-size: 4vw;
    line-height: 4vw;
  }

  .container .contValue .circleContainer {
    width: 15vw;
    height: 15vw;
  }

  .container .contValue .circleContainer h3 {
    font-size: 7vw;
    line-height: 7vw;
  }

  .container .contValue .circleContainer .bgCircle {
    width: 14.4vw;
    height: 14.4vw;

    border: .3vw solid #FFF;
  }

  .container .contValue .circleContainer div.svg {
    width: 15vw;
    height: 15vw;
  }
}