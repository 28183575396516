@import '../standar.css';

.welcomeContainer {
  position: relative;

  width: 100vw;
  height: 100vh;

  margin: auto;

  display: flex;
}

.welcomeContainer .imgBg {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-image: url('../../images/Welcome-gris.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.welcomeContainer .dateLogo {
  position: absolute;
  top: 5vh;
  right: 5vw;

  z-index: 20;

  width: 80px;
  height: 110px;

  background-size: 90%;
  background-position: center;
  background-repeat: no-repeat;

  margin: auto;
  margin-bottom: 5%;
}

.marzo {
  background-image: url('../../images/dateLogo.png');
}

.mar17 {
  background-image: url('../../images/mar17Logo-dark.png');
}

.mar18 {
  background-image: url('../../images/mar18Logo-dark.png');
}

.arrow {
  position: relative;
  left: 15px;

  width: 30px;
  height: 30px;

  margin: auto;

  background-color: #FFF;
  border-radius: 20px;

  color: var(--color-dark-blue);
  font-size: 18px;
  font-weight: 800;

  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow-left {
  position: relative;
  left: -10px;

  width: 20px;
  height: 20px;

  margin: auto;

  background-color: #FFF;
  border-radius: 20px;

  color: var(--color-dark-blue);
  font-size: 14px;
  font-weight: 800;

  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingCircle {
  margin: auto;
}

/* ================================================ */

.backCont {
  position: absolute;
  top: 1vh;
  left: 0vw;

  width: 100%;
  margin: auto;

  display: flex;
  justify-content: space-between;

  z-index: 2000;
}

.backCont .backBtn {
  padding: 5px 20px;

  outline: none;
  border: none;
  border-radius: 100px;
  background: var(--color-light-blue);
  background: linear-gradient(90deg, var(--color-light-blue) 0%, var(--color-dark-blue) 100%);

  color: #FFF;
  font-family: var(--font-Baloo2-Regular);
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
}
.backCont .backBtn p {
  margin: auto;
}

.roomBack, .close {
  margin: auto 10px;
}

/* ================================================ */

.welcomeContainer .inputs {
  z-index: 10;

  width: 100%;
  min-width: 200px;

  margin: auto;
  margin-bottom: 15vh;

  display: flex;
  flex-wrap: wrap;
}

.welcomeContainer .inputs .loginLink, .agenda {
  min-width: 100px;
  margin: auto;
  margin-top: 50px;
  padding: 10px 30px;

  outline: none;
  border: none;
  border-radius: 100px;
  background: var(--color-light-blue);
  background: linear-gradient(90deg, var(--color-light-blue) 0%, var(--color-dark-blue) 100%);

  color: #FFF;
  font-family: var(--font-Baloo2-Regular);
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;
  text-decoration: none;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .backCont .backBtn p {
    display: none;
  }

  .welcomeContainer .imgBg {
    background-image: url('../../images/Welcome-gris-Mobile.png');
  }

  .welcomeContainer .inputs {
    margin: auto;
    margin-bottom: 5vh;
  }

  .welcomeContainer .inputs .loginLink, .agenda {
    min-width: 100px;
    margin: 10px auto;
    padding: 8px 25px;
    font-size: 20px;
    line-height: 30px;
  }
}