/* Baloo */

@font-face {
  font-family: Baloo2-Bold;
  src: url('./Baloo2-Bold.woff');
}

@font-face {
  font-family: Baloo2-ExtraBold;
  src: url('./Baloo2-ExtraBold.woff');
}

@font-face {
  font-family: Baloo2-Medium;
  src: url('./Baloo2-Medium.woff');
}

@font-face {
  font-family: Baloo2-Regular;
  src: url('./Baloo2-Regular.woff');
}

@font-face {
  font-family: Baloo2-SemiBold;
  src: url('./Baloo2-SemiBold.woff');
}

/* Futura */

@font-face {
  font-family: Futura-Cond-Bold;
  src: url('./futura-condensed-bold.woff');
}