@import '../components/fonts/fonts.css';

:root {
  --color-light-green: #23D6B8;
  --color-dark-green: #0D8079;
  --color-dark-blue: #083E66;
  --color-light-blue: #1E94FF;

  --font-Baloo2-Bold: Baloo2-Bold;
  --font-Baloo2-ExtraBold: Baloo2-ExtraBold;
  --font-Baloo2-Medium: Baloo2-Medium;
  --font-Baloo2-Regular: Baloo2-Regular;
  --font-Baloo2-SemiBold: Baloo2-SemiBold;
  --font-Futura-Cond-Bold: Futura-Cond-Bold;
}
