div[class^="triviaUser"] {
  width: 100%;

  margin: auto;
  padding: 10px;

  display: flex;

  background-color: var(--color-dark-blue);
  background: radial-gradient(circle, var(--color-light-blue) 10%, var(--color-dark-blue) 100%);
  color: #FFF;
  border-radius: 10px;
}

.triviaUser1 {
  justify-content: center;
  align-items: center;
}

.triviaUser1 h1 {
  line-height: 1.2vw;
  font-size: 1.2vw;
  text-align: center;
}

.triviaUser2 {
  flex-flow: column;
}

.triviaUser2 .triviaTimeAndQuestion {
  width: 90%;
  margin: auto;
  display: flex;
  flex-flow: column;

  border-bottom: 1px solid #FFF;
}

.triviaUser2 .triviaTimeAndQuestion h3 {
  font-size: 4vw;
  line-height: 4.5vw;
  font-weight: 100;
  margin: 0;

  margin: auto;
}

.triviaUser2 .triviaTimeAndQuestion h2 {
  font-size: 1.2vw;
  line-height: 1.2vw;
  font-family: var(--font-Baloo2-Bold);
  margin: 0;

  margin: 10px auto;
}

/* ====================================================== */

.triviaUser2 .triviaAnswers {
  width: 100%;

  margin: 10px auto;

  display: flex;
  flex-flow: column;
}

.triviaUser2 .triviaAnswers h2 {
  line-height: 1vw;
  font-size: 1vw;
  font-weight: 200;

  margin: auto;
}

.triviaUser2 .triviaAnswers .answersCont {
  width: 90%;

  margin: auto;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.triviaUser2 .triviaAnswers .answersCont .triviaAnswer {
  margin: 5px;
  padding: 2% 5%;

  border: 0;
  border-radius: 5px;

  cursor: pointer;

  min-width: 10%;
  background-color: white;
  font-weight: 900;
  color: #AAA;
}

.triviaUser2 .triviaAnswers .triviaAnswer:hover {
  color: var(--color-dark-blue);
  transform: scale(.97);
}

/* ================================================== */

.triviaUser5 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* ==================================================== */


@media (max-width: 800px) {
  .triviaUser2 {
    flex-direction: column;
  }

  .triviaUser2 .triviaTimeAndQuestion h2 {
    font-size: 3.2vw;
    line-height: 3.2vw;

    margin: 10px auto;
  }

  .triviaUser1 h1 {
    line-height: 3.2vw;
    font-size: 3.2vw;
  }
}
