@import './pages/standar.css';

html {
  scrollbar-width: thin;
  scrollbar-color: var(--color-light-blue) var(--color-dark-blue);

  overflow-x: hidden;
  overflow-y: auto;
}

/* width and height */
*::-webkit-scrollbar {
  width: 10px; height: 10px;
}

/* Track */
*::-webkit-scrollbar-track {
  background: var(--color-dark-blue);
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: var(--color-light-blue);
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: var(--color-light-blue);
}

body {
  margin: 0;
  padding: 0;
  background-color: #FFF;

  font-family: -apple-system, var(--font-Baloo2-Regular), 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100vw;
  min-height: 100vh;

  overflow-x: hidden;
  overflow-y: auto;
}

body #root {
  width: 100vw;
  min-height: 100vh;

  margin: auto;

  display: flex;
  flex-flow: column;
}

/* ================================= */


body {
  margin: 0;
  padding: 0;

  font-family: -apple-system, var(--font-Baloo2-Regular), 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
