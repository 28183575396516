.room {
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  min-height: 100vh;
  background: url('../../images/bgRoom-blue.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.room .roomContent {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  width: 100vw;
  height: max-content;
  min-height: 75vh;

  margin: auto;
}

.room .roomContent .roomLeft {
  width: 75%;
  height: 90vh;
  min-width: 300px;

  display: flex;
  flex-flow: column;

  margin: auto;
}

/* =============================== */

.room .roomContent .roomLeft .roomVideoContainer {
  position: relative;

  width: 69vw;
  height: calc(71vw / 1.77777);

  margin: auto;

  display: flex;
}

.room .roomContent .roomLeft .roomVideoContainer .iframeCont {
  width: 100%;
  height: 100%;

  margin: auto;

  display: flex;
}

.room .roomContent .roomLeft .roomVideoContainer .iframeCont iframe {
  width: 98%;
  height: 99%;
  border: 0;

  margin: auto;
}

/* ======================================================== */

.room .roomContent .roomRight {
  width: 15%;
  height: 90vh;
  min-width: 300px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;

  margin: auto;
  margin-left: 0;
}

.room .roomContent .roomRight .roomChatContainer {
  height: 30%;
  width: 100%;
  padding: 1vw;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 5px;

  margin: 10px auto;
}

/* ====================================================== */

.room .roomContent .roomRight .gamecontainer {
  width: 100%;

  margin: 10px auto;

  display: flex;
  flex-flow: column;
}

.room .roomContent .roomRight .gamecontainer .chartContainer {
  background-color: rgba(255, 255, 255, 0.95);

  width: 90%;

  margin: auto;
  padding: 10px;

  border-radius: 10px;

  display: flex;
}

.room .roomContent .roomRight .gamecontainer .chartContainer #myChart {
  margin: auto;
}

.room .roomContent .roomRight .gamecontainer .roomTriviaContainer {
  width: 100%;

  margin: 20px auto;

  display: flex;
}


/* ================================================= */
/* ================================================= */
/* ================================================= */
/* ================================================= */



@media screen and (max-height: 640px) {
  .room .roomContent .roomLeft .roomVideoContainer {
    width: 45vw;
    height: calc(45vw / 1.77777);
  }

  .room .roomContent .roomLeft .roomVideoContainer .cenefa {
    position: absolute;
    bottom: -10%;
    left: -5.3%;

    width: 49vw;
    height: calc(49vw / 8.1441);

    margin: auto;

    background-image: url('../../images/Cenefa.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .room .roomContent .roomRight {
    width: 20%;
    height: 80vh;
    min-width: 250px;
  }
}


/* ================================================= */
/* ================================================= */
/* ================================================= */
/* ================================================= */

@media screen and (max-width: 1024px) {
  .room .roomContent .roomLeft {
    width: 65%;
    height: 90vh;
    min-width: 300px;
  }

  .room .roomContent .roomLeft .roomVideoContainer {
    width: 60vw;
    height: calc(61vw / 1.77777);
  }
}

@media screen and (max-width: 768px) {
  .room .roomContent {
    display: flex;
    flex-direction: column;

    margin: 0px auto 100px auto;
  }

  .room .roomContent .roomLeft {
    width: 98%;
    height: calc(97vw / 1.777);
    min-width: 250px;

    margin: 80px auto 20px auto;
  }

  .room .roomContent .roomLeft .roomVideoContainer {
    width: 95vw;
    height: calc(96vw / 1.77777);
  }

  /* ----------------------------------------- */

  .room .roomContent .roomRight {
    width: 90%;
    height: 70vh;
    min-width: 250px;

    margin: auto;
  }

  .room .roomContent .roomRight .roomChatContainer {
    height: 45%;
    width: 85%;

    box-shadow: 2px 2px 15px var(--color-dark-blue);
  }

  /* ============================================ */

  .room .kvEvent {
    position: relative;

    margin: auto;
  }

  /* ================================================== */
}
