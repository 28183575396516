.supportContainer {
  z-index: 200;

  position: fixed;
  bottom: 20px;
  right: 20px;

  display: flex;
}

.supportContainer a {
  margin: auto;

  outline: none;
  text-decoration: none;

  display: flex;
  flex-flow: column;
}

.supportContainer a .title {
  position: relative;
  top: -10px;

  display: flex;
}

.supportContainer a .title .msgBox {
  display: none;

  margin: auto;
  padding: 5px 10px;

  background-color: var(--color-dark-blue);
  color: #FFF;
  text-decoration: none;
  border-radius: 10px;
}

.supportContainer a:hover .msgBox {
  display: block;
  background-color: var(--color-dark-blue);
}

.supportContainer a:hover .circle {
  position: absolute;
  bottom: -12px;
  right: 40px;

  width: 10px;
  height: 10px;

  border: none;
  border-radius: 10px;

  background-color: var(--color-dark-blue);
}

.supportContainer a .imgIcon {
  width: 50px;
  min-width: 50px;
  height: 50px;

  border-radius: 100px;

  margin: auto 0 auto auto;

  background-color: var(--color-dark-blue);
  background-image: url('../../images/support-icon.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;

  transition: .15s;
}

.supportContainer a:hover .imgIcon {
  background-color: var(--color-light-blue);
  background-size: 60%;
}

@media screen and (max-width: 768px) {
  .supportContainer {
    z-index: 200;
  
    position: fixed;
    bottom: 5px;
    right: 5px;
  
    display: flex;
  }
}