.chat {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.chat .chatBuffer {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 2.5%;
  margin: 0px auto 10px auto;
  width: 95%;
  height: 100%;

  max-height: 440px;

  scrollbar-width: thin;
  scrollbar-color: #002735 transparent;
}

.chat .chatBuffer::-webkit-scrollbar-track {
  background: transparent;
}

.chat .chatCard {
  width: 80%;
  padding: 20px;
  background-color: var(--color-light-blue);
  border-radius: 10px 10px 10px 0px;
  margin-bottom: 1vh;
  color: #FFF;
}

.chat .chatOwnCard {
  display: flex;
  flex-direction: column;
  width: 80%;
  padding: 10px 20px;
  background-color: var(--color-dark-blue);
  border-radius: 10px 10px 0px 10px;
  margin-bottom: 1vh;
  color: white;
  align-self: flex-end;
}

.chat .chatOwnCard p {
  text-align: end;
}

.chat .chatUsername {
  margin: 0px;
  font-size: 12px;
  font-weight: 300;
}

.chat .chatMessage {
  overflow-wrap: anywhere;
  font-size: 1rem;
  margin: 5px 0px;
}

.chat .response {
  border-top: 1px solid #FFF;
}

.chat .chatForm {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.chat .chatInput {
  position: relative;

  width: 100%;
  height: 30px;
  border-radius: 50px;
  outline: none;
  border: solid 1px  var(--color-light-blue);
  padding: 5px 40px 5px 20px;

  color: var(--color-dark-blue);
  font-size: 18px;
}

.chat .chatInput::placeholder {
  color: #CCC;
}

.chat .chatInput:focus {
  border: solid 1px  var(--color-dark-blue);
}

.chat .chatInputButton {
  position: absolute;
  top: 15%;
  right: 2%;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50px;
  cursor: pointer;
  background-color: var(--color-light-blue);
  border: none;
  font-weight: 900;
  font-size: 16px;
  color: white;
  width: 30px;
  height: 30px;
  min-width: 30px;
  outline: none;
}

.chat .chatInputButton:hover {
  background-color: var(--color-dark-blue);
}