form {
  width: 100%;

  margin: auto;

  display: flex;
  justify-content: center;
}

form .containerForm {
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: center;
}

form .containerForm input {
  display: none;
}

form .containerForm .inputFileLb {
  padding: 10px 20px;
  margin: 10px;

  border: none;
  outline: none;
  border-radius: 5px;

  background-color: #DDD;
  cursor: pointer;

  font-size: 18px;
  color: #555;
}

form .containerForm .inputFileLb:hover {
  background-color: #AAA;
  color: #000;
}

form .containerForm .fileCont {
  margin: 10px;

  display: flex;
}

form .containerForm .fileCont .fileName {
  margin: auto;
  padding: 10px;

  color: #000;
  font-weight: 600;

  border-style: dashed;
  border-width: 2px;
  border-color: #888;
}

form .containerForm .remove {
  padding: 10px 20px;
  margin: 10px;

  border: none;
  outline: none;
  border-radius: 5px;

  background-color: #DDD;
  cursor: pointer;

  font-size: 18px;
  color: #555;
}

form .containerForm .remove:hover {
  background-color: #AAA;
  color: #000;
}

form .submit {
  padding: 10px 20px;
  margin: 20px auto;

  border: none;
  outline: none;
  border-radius: 5px;

  background-color: #DDD;
  cursor: pointer;

  font-size: 18px;
  color: #555;
}

form .submit:hover {
  background-color: #AAA;
  color: #000;
}

.titleImg {
  margin: auto;
  color: #999;
}