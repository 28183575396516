@import '../standar.css';

.lobbyContainer {
  position: relative;

  width: 100vw;
  height: 100vh;

  margin: auto;

  display: flex;
  flex-flow: column;
}

.lobbyContainer .imgBg {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-image: url('../../images/Lobby-circular.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.lobbyContainer .dateLogo {
  position: absolute;
  top: 2vh;
  right: 5vw;

  z-index: 20;

  width: 80px;
  height: 110px;

  background-size: 90%;
  background-position: center;
  background-repeat: no-repeat;

  margin: auto;
  margin-bottom: 5%;
}

.lobbyContainer .counterContainer {
  z-index: 10;

  width: 50%;

  margin: 10vh auto auto auto;

  display: flex;
}

/* ================================================ */

.lobbyContainer .inputs {
  z-index: 10;

  width: 100%;

  margin: auto;
  margin-bottom: 16vh;

  display: flex;
  flex-wrap: wrap;
}

.lobbyContainer .inputs .btnLinks {
  margin: auto;
  margin-top: 50px;
  padding: 10px 30px;

  outline: none;
  border: none;
  border-radius: 100px;
  background: var(--color-light-blue);
  background: linear-gradient(90deg, var(--color-light-blue) 0%, var(--color-dark-blue) 100%);

  color: #FFF;
  font-family: var(--font-Baloo2-Regular);
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;
  text-decoration: none;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .lobbyContainer .imgBg {
    background-image: url('../../images/Lobby-circular-mobile.png');
  }

  .lobbyContainer .inputs {
    margin: auto;
    margin-bottom: 10vh;
  }

  .lobbyContainer .inputs .btnLinks {
    margin: 10px auto;
    font-size: 4vw;
  }

  .lobbyContainer .dateLogo {
    width: 10vw;
    height: 15vw;
  }
}

