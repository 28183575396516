@import '../../pages/standar.css';

.selectContainer {
  width: 90%;

  margin: auto;

  display: flex;
  flex-flow: column;
}

.selectContainer h2 {
  margin: 10px auto;

  font-size: 24px;
  font-weight: 300;
  font-family: var(--font-main-font);
  color: #FFF;
  text-align: center;
}

.selectContainer .templateOptions {
  width: 90%;
  max-width: 400px;

  margin: auto;

  border-radius: 20px;
  outline: none;
}

span {
  border: none;
  outline: none;
}

.templateOptions div[class$='control'] {
  cursor: pointer;

  border-radius: 20px;
  border: none;
  outline: none;
}