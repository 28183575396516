.adminContainer {
  width: 100%;

  margin: auto;

  display: flex;
  flex-flow: column;

  overflow-x: hidden;
  overflow-y: scroll;

  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  color: #333;

  background-color: #FFF;
}

.adminContainer .card {
  position: relative;
  width: 85%;

  margin: 20px auto;
  padding: 20px;

  background-color: #FFF;
  box-shadow: 3px 3px 15px #DDD;
  border-radius: 20px;

  display: flex;
  flex-flow: column;
}

/* ==================================================== */

.adminContainer .title {
  width: 100%;

  margin: 10px auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.adminContainer .title h2 {
  font-size: 40px;
  font-weight: 100;

  color: #888;
}

/* =========================================== */

.chatAdmin {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  width: 95%;
  min-height: 300px;

  margin: auto;

  background-color: #FFF;
}

.chatAdmin .adminChatBuffer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2%;
  margin: 10px 20px;
  width: 95%;
  height: 100%;

  max-height: 500px;
  min-height: 300px;

  scrollbar-width: thin;
  scrollbar-color: #002735 transparent;

  box-shadow: 3px 3px 10px #CCC;
  border-radius: 10px;
}

.chatAdmin .adminChatBuffer::-webkit-scrollbar-track {
  background: transparent;
}

.chatAdmin .chatAdminCard {
  position: relative;

  width: 80%;
  padding: 20px;
  background-color: #FFF;
  box-shadow: 3px 3px 10px #CCC;
  border-radius: 10px 10px 10px 0px;
  margin-bottom: 10px;
  color: #333;

  display: flex;
}

.chatAdmin .chatAdminCard .incomeMsg {
  width: 100%;
  height: 100%;

  margin: auto;
}

.chatAdmin .chatAdminCard .incomeMsg .userInfo {
  display: flex;
}

.chatAdmin .chatAdminCard .incomeMsg .chatUsername {
  font-size: 12px;
  line-height: 12px;
  margin: 0;
  color: #AAA;
}

.chatAdmin .chatAdminCard .incomeMsg .chatTime {
  font-size: 12px;
  line-height: 12px;
  margin: 0 10px;
  color: #AAA;
}

.chatAdmin .chatAdminCard .incomeMsg .chatMessage {
  font-size: 16px;
  line-height: 16px;
  margin: 10px auto;
  color: #222;
}

.chatAdmin .chatAdminCard .incomeMsg .chatAdminText {
  width: 90%;
  height: 18px;

  margin: 10px auto;
  padding: 10px 20px;

  border: none;
  outline: none;
  border-radius: 10px;
  box-shadow: 3px 3px 10px #AAA;

  font-size: 16px;
  line-height: 16px;
  color: #222;
}

.chatAdmin .chatAdminCard .incomeMsg .chatAdminText:focus {
  color: #000;
  border: 1px solid #AAA;
}

.chatAdmin .chatAdminCard .chatAdminButtons {
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.chatAdmin .chatAdminCard .chatAdminButtons .adminSendButton {
  width: 40px;
  min-width: 40px;
  height: 40px;

  border-radius: 50vw;
  border: 0px;
  outline: none;

  background-color: #00677F;
  color: white;
  font-weight: 600;
  margin: 10px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.chatAdmin .chatAdminCard .chatAdminButtons .adminDeleteButton {
  width: 40px;
  min-width: 40px;
  height: 40px;

  border-radius: 50vw;
  border: 0px;
  outline: none;

  background-color: #FF066C;
  color: white;
  font-weight: 600;
  margin: 10px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.chatAdmin .chatUserContainer {
  height: 90%;
  width: 80%;
  padding: 1vw;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 10px;

  box-shadow: 3px 3px 10px #AAA;

  margin: auto;
}

/* ================================================================== */

.agendaMan .checkActivate {
  position: absolute;
  top: 10px;
  right: 5%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.agendaMan .checkActivate h2 {
  font-size: 20px;
  color: #777;

  margin: 10px;
}

/* ================================================================== */

.adminContainer .onlineCont .listCont {
  width: 100%;

  margin: auto;

  display: flex;
  flex-wrap: wrap;
}

.adminContainer .onlineCont .listCont .action {
  width: 20%;
  min-width: 100px;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  margin: auto;
}

.adminContainer .onlineCont .listCont .action form {
  width: 100%;

  display: flex;
  flex-flow: column;
}

.adminContainer .onlineCont .listCont .action form input {
  margin: auto;
  padding: 5px 10px;

  outline: none;
  border: none;
  border-radius: 5px;
  box-shadow: 2px 2px 5px #CCC;
  color: #000;
}

.adminContainer .onlineCont .listCont .action h3 {
  text-align: center;
  color: #777;
}

.adminContainer .onlineCont .especificUser {
  margin: 10px auto;
  padding: 10px 20px;

  display: flex;

  box-shadow: 2px 2px 5px #CCC;
  border-radius: 5px;
}

.adminContainer .onlineCont .especificUser p {
  margin: 10px;
}

.adminContainer .adminButton {
  padding: 10px 20px;
  margin: 10px;

  border: none;
  outline: none;
  border-radius: 5px;

  background-color: #DDD;
  cursor: pointer;

  font-size: 18px;
  color: #555;
}

.adminContainer .adminButton:hover {
  background-color: #AAA;
  color: #000;
}

.adminContainer .onlineCont .listCont .listRegisters {
  margin: auto;
  min-width: 200px;
  max-height: 300px;

  display: flex;
  flex-flow: column;

  overflow-x: hidden;
  overflow-y: scroll;
}

.adminContainer .onlineCont .listCont .listRegisters .userInfo {
  margin: 10px auto;

  display: flex;

  border-bottom: 1px solid #CCC;
}

.adminContainer .onlineCont .listCont .listRegisters .userInfo p {
  margin: 10px;
}

/* ================================================================== */

.adminContainer .registers .listCont {
  width: 100%;

  margin: auto;

  display: flex;
  flex-wrap: wrap;
}

.adminContainer .registers .listCont .action {
  width: 20%;
  min-width: 100px;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  margin: auto;
}

.adminContainer .registers .listCont .action h3 {
  text-align: center;
  color: #777;
}

.adminContainer .adminButton {
  padding: 10px 20px;
  margin: 10px;

  border: none;
  outline: none;
  border-radius: 5px;

  background-color: #DDD;
  cursor: pointer;

  font-size: 18px;
  color: #555;
}

.adminContainer .adminButton:hover {
  background-color: #AAA;
  color: #000;
}

.adminContainer .registers .listCont .listRegisters {
  margin: auto;
  min-width: 200px;
  max-height: 300px;

  display: flex;
  flex-flow: column;

  overflow-x: hidden;
  overflow-y: scroll;
}

.adminContainer .registers .listCont .listRegisters .userInfo {
  margin: 10px auto;

  display: flex;

  border-bottom: 1px solid #CCC;
}

.adminContainer .registers .listCont .listRegisters .userInfo p {
  margin: 10px;
}

/* ========================================================== */

.adminContainer .memories .controls {
  width: 100%;

  margin: 10px auto;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.adminContainer .memories .controls div[class^="controlsDay"] {
  width: 50%;
  min-width: 200px;

  display: flex;
  flex-flow: column;
}

.adminContainer .memories .controls div[class^="controlsDay"] h2 {
  color: #555;
  font-size: 18px;
  text-align: center;

  width: 100%;
}

.adminContainer .memories .controls div[class^="controlsDay"] .btnsCont {
  width: 100%;
  min-width: 200px;

  margin: 10px auto;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.adminContainer .memories .controls div[class^="controlsDay"] .btnsCont button {
  margin: 10px;
  padding: 5px 10px;

  border: none;
  outline: none;
  border-radius: 5px;

  background-color: #DDD;
  cursor: pointer;

  font-size: 18px;
  color: #555;
}

.adminContainer .memories .controls div[class^="controlsDay"] .btnsCont button:hover {
  background-color: #AAA;
  color: #000;
}

.adminContainer .memories .input {
  width: 90%;
  margin: 10px auto;

  display: flex;
}

.adminContainer .memories .input input {
  width: 90%;

  margin: auto;
  padding: 10px 20px;

  border: none;
  border-radius: 2px;
  outline: none;
  box-shadow: 2px 2px 10px #DDD;

  font-family: var(--font-COLGATEREADY);
  font-size: 20px;
  color: #777;
}

.adminContainer .memories .input input::placeholder {
  color: #CCC;
  font-size: 18px;
}

.adminContainer .memories .input input:focus {
  color: #555;
}

.adminContainer .memories .input input:invalid {
  color: #FF0000;
}

.adminContainer .memories .input input:-webkit-autofill {
  border: 1px solid var(--color-light-cian);
  -webkit-text-fill-color: var(--color-light-green);
  -webkit-box-shadow: 0 0 0px 10000px #FAFAFA inset;
  box-shadow: 0 0 0px 10000px #FAFAFA inset;
}

.adminContainer .memories .frame {
  position: relative;

  width: 450px;
  height: 250px;

  background-color: #EEE;

  margin: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;
}

.adminContainer .memories .userStream {
  position: absolute;
  top: 0%;
  right: 0%;

  margin: auto 0 auto auto;

  border-radius: 5px;
}

.adminContainer .memories .userStream iframe {
  width: 250px;
  height: 150px;
}

.adminContainer .memories .frame iframe {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  margin: auto;
}

.adminContainer .memories .actions {
  width: 100%;

  margin: 50px auto 10px auto;

  display: flex;
  align-items: center;
  justify-content: center;
}

.adminContainer .memories .actions .btnActions {
  padding: 10px 20px;
  margin: 10px;

  border: none;
  outline: none;
  border-radius: 5px;

  background-color: #DDD;
  cursor: pointer;

  font-size: 18px;
  color: #555;
}

.adminContainer .memories .actions .btnActions:hover {
  background-color: #AAA;
  color: #000;
}

/* ---------------------------------- */

#btnSelected {
  background-color: #002735;
  color: #FFF;
}

/* ========================================================== */

.adminContainer .streaming .input {
  width: 90%;
  margin: 10px auto;

  display: flex;
}

.adminContainer .streaming .input input {
  width: 90%;

  margin: auto;
  padding: 10px 20px;

  border: none;
  border-radius: 2px;
  outline: none;
  box-shadow: 2px 2px 10px #DDD;

  font-family: var(--font-COLGATEREADY);
  font-size: 20px;
  color: #777;
}

.adminContainer .streaming .input input::placeholder {
  color: #CCC;
  font-size: 18px;
}

.adminContainer .streaming .input input:focus {
  color: #555;
}

.adminContainer .streaming .input input:invalid {
  color: #FF0000;
}

.adminContainer .streaming .input input:-webkit-autofill {
  border: 1px solid var(--color-light-cian);
  -webkit-text-fill-color: var(--color-light-green);
  -webkit-box-shadow: 0 0 0px 10000px #FAFAFA inset;
  box-shadow: 0 0 0px 10000px #FAFAFA inset;
}

.adminContainer .streaming .frame {
  position: relative;

  width: 450px;
  height: 250px;

  background-color: #EEE;

  margin: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;
}

.adminContainer .streaming .userStream {
  position: absolute;
  top: 0%;
  right: 0%;

  margin: auto 0 auto auto;

  border-radius: 5px;
}

.adminContainer .streaming .userStream iframe {
  width: 250px;
  height: 150px;
}

.adminContainer .streaming .frame iframe {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  margin: auto;
}

.adminContainer .streaming .actions {
  width: 100%;

  margin: 50px auto 10px auto;

  display: flex;
  align-items: center;
  justify-content: center;
}

.adminContainer .streaming .actions .btnActions {
  padding: 10px 20px;
  margin: 10px;

  border: none;
  outline: none;
  border-radius: 5px;

  background-color: #DDD;
  cursor: pointer;

  font-size: 18px;
  color: #555;
}

.adminContainer .streaming .actions .btnActions:hover {
  background-color: #AAA;
  color: #000;
}

/* ======================= */
