@import '../standar.css';

.memoriesContainer {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-flow: column;

  background-image: url('../../images/bgMemories.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.memoriesContainer .memCont {
  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: column;
  justify-content: center;
}

.memoriesContainer .memCont .iframeCont {
  width: 50vw;
  height: calc(51vw / 1.7777);

  margin: 10px auto;

  display: flex;
  justify-content: center;
  align-items: center;
}

.memoriesContainer .memCont .iframeCont iframe {
  width: 98%;
  height: 99%;

  overflow-y: hidden;
}

/* ========================================================== */

.memoriesContainer .memCont .controls {
  position: relative;

  width: 90%;

  margin: 10px auto;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  background-color: #FFF;
  border-radius: 10px;
  box-shadow: 3px 3px 10px var(--color-dark-blue);
}

.memoriesContainer .memCont .controls .btnMenu {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.memoriesContainer .memCont .controls .btnMenu button[class^="btnMemoriasdia"] {
  width: 50%;

  margin: auto;
  padding: 10px;

  border: none;
  outline: none;

  cursor: pointer;

  text-transform: uppercase;
  font-size: 18px;
  line-height: 18px;
  font-family: var(--font-Baloo2-SemiBold);
}

.memoriesContainer .memCont .controls .btnMenu .btnMemoriasdia1 {
  color: #AAA;
  background: #FFF;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.8)  70%, rgba(200, 200, 200, 1) 100%);
}

.memoriesContainer .memCont .controls .btnMenu .btnMemoriasdia2 {
  color: #AAA;
  background: #FFF;
  background: linear-gradient(90deg, rgba(200, 200, 200, 1) 0%, rgba(255, 255, 255, 0) 70%);
}

.memoriesContainer .memCont .controls .btnMenu .selected {
  background: rgba(255, 255, 255, 0);
  color: var(--color-dark-blue);
}

/* ========================================= */

.memoriesContainer .memCont .controls div[class^="dia"] {
  min-width: 250px;
  min-height: 160px;
  max-height: 160px;
  width: 100%;

  margin: 60px 2vw 2vh 2vw;
  padding: 10px;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  overflow-x: hidden;
  overflow-y: auto;
}

.memoriesContainer .memCont .controls div[class^="dia"] div[class^="miniatura-"] {
  width: 288px;
  height: 162px;

  margin: 10px;

  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;

  cursor: pointer;
}

.memoriesContainer .memCont .controls div[class^="dia"] div[class^="miniatura-"]:hover {
  transform: scale(0.97);
}

.memoriesContainer .memCont .controls div[class^="dia"] .miniatura-dia1-1 {
  background-image: url('../../images/memorias/dia1/1.png');
}

.memoriesContainer .memCont .controls div[class^="dia"] .miniatura-dia1-2 {
  background-image: url('../../images/memorias/dia1/2.png');
}

.memoriesContainer .memCont .controls div[class^="dia"] .miniatura-dia1-3 {
  background-image: url('../../images/memorias/dia1/3.png');
}

.memoriesContainer .memCont .controls div[class^="dia"] .miniatura-dia1-4 {
  background-image: url('../../images/memorias/dia1/4.png');
}

.memoriesContainer .memCont .controls div[class^="dia"] .miniatura-dia1-5 {
  background-image: url('../../images/memorias/dia1/5.png');
}

.memoriesContainer .memCont .controls div[class^="dia"] .miniatura-dia1-6 {
  background-image: url('../../images/memorias/dia1/6.png');
}

/* ==================================================================== */

.memoriesContainer .memCont .controls div[class^="dia"] .miniatura-dia2-1 {
  background-image: url('../../images/memorias/dia2/1.png');
}

.memoriesContainer .memCont .controls div[class^="dia"] .miniatura-dia2-2 {
  background-image: url('../../images/memorias/dia2/2.png');
}

.memoriesContainer .memCont .controls div[class^="dia"] .miniatura-dia2-3 {
  background-image: url('../../images/memorias/dia2/3.png');
}

.memoriesContainer .memCont .controls div[class^="dia"] .miniatura-dia2-4 {
  background-image: url('../../images/memorias/dia2/4.png');
}

.memoriesContainer .memCont .controls div[class^="dia"] .miniatura-dia2-5 {
  background-image: url('../../images/memorias/dia2/5.png');
}

@media screen and (max-width: 768px) {
  .memoriesContainer .memCont .iframeCont {
    width: 95vw;
    height: calc(95vw / 1.777)
  }
}