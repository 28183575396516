@import '../standar.css';

.registerContainer {
  position: relative;

  width: 100vw;
  height: 100vh;

  margin: auto;

  display: flex;
}

.registerContainer .imgBg {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-image: url('../../images/bgRegister-verde.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.registerContainer .dateLogo {
  position: absolute;
  top: 5vh;
  right: 5vw;

  z-index: 20;

  width: 80px;
  height: 110px;

  background-size: 90%;
  background-position: center;
  background-repeat: no-repeat;

  margin: auto;
  margin-bottom: 5%;
}

/* ================================================ */

.registerContainer .inputs {
  z-index: 10;

  width: 100%;

  margin: auto;
  margin-bottom: 20vh;

  display: flex;
  flex-flow: column;
}

.registerContainer .inputs .form {
  width: 90%;

  margin: auto;
  padding: 20px 20px;

  display: flex;
  flex-flow: column;
}

.registerContainer .inputs .form .inputsCont {
  width: 100%;

  margin: 10px auto;

  display: flex;
  justify-content: space-between;
}

.registerContainer .inputs .form input {
  margin: auto;
  padding: 10px 30px;

  outline: none;
  border: none;
  border-radius: 100px;
  background: var(--color-light-green);
  background: linear-gradient(90deg, var(--color-light-green) 0%, var(--color-dark-green) 100%);

  font-family: var(--font-Baloo2-Regular);
  font-size: 20px;
  color: #FFF;
  text-align: center;
}

.registerContainer .inputs .form input::placeholder {
  color: #2df5d3;
}

.registerContainer .inputs .form input:focus {
 border: 1px solid #FFF;
}

.registerContainer .inputs .form input:invalid {
  color: #FF0000;
}

.registerContainer .inputs .form input:-webkit-autofill {
  border: 1px solid var(--color-light-green);
  -webkit-text-fill-color: #FFF;
  -webkit-box-shadow: 0 0 0px 10000px var(--color-light-green) inset;
  box-shadow: 0 0 0px 10000px var(--color-light-green) inset;
}

.registerContainer .inputs .form button {
  margin: auto;
  margin-top: 50px;
  padding: 10px 30px;

  outline: none;
  border: none;
  border-radius: 100px;
  background: var(--color-light-green);
  background: linear-gradient(90deg, var(--color-light-green) 0%, var(--color-dark-green) 100%);

  color: #FFF;
  font-family: var(--font-Baloo2-Regular);
  font-size: 30px;
  line-height: 30px;
  text-transform: uppercase;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
}

.registerContainer .inputs .form button:disabled {
  color: #CCC;
  cursor: default;
}

.registerContainer .inputs .button {
  margin: 10px auto;
  padding: 10px 20px;

  outline: none;
  border: none;
  border-radius: 2px;
  background-color: #FFF;

  color: var(--color-light-green);
  font-family: var(--font-COLGATEREADY);
  font-size: 20px;
  text-decoration: none;

  cursor: pointer;
}

.registerContainer .inputs .login {
  position: absolute;
  top: 2%;
  right: 5%;
}

.registerContainer .inputs .boxmessage {
  z-index: 20;

  width: 100%;

  margin: 10px auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.registerContainer .inputs .boxmessage h2 {
  margin: auto;
  width: 100%;

  font-family: var(--font-ARCA-MAJORA);
  color: var(--color-dark-green);
  text-align: center;
}

/* ========================================= */

@media screen and (max-width: 1024px) {
}